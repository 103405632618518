// deep imports to fix circular deps in jest
import { Model } from '../model/model';
import { customMetadataStorage } from '../model/custom-metadata';
import { AppRequestMethod } from '../request/request-method';
import { appRequestSupportsHttp } from './helpers';
import { getAllClassTransformerMetadata } from '../model';
// not exported as considered private. Use provided functions to get metadata.
const APP_REQUEST_KEY = '__APP_REQUEST__';
// reminder: Server cache is configurated in OnAppRequest!
export function AppRequest(namespace, path, config) {
  return function SetRequestMeta(model) {
    customMetadataStorage.addMetadata({
      metaType: APP_REQUEST_KEY,
      target: model,
      propertyName: null,
      options: {
        namespace,
        path,
        config
      }
    });
    if (!IS_PRODUCTION) {
      if (appRequestSupportsHttp(config) && config.httpMethod === AppRequestMethod.GET) {
        /**
         * If AppRequest uses http GET, properties need to be transferred as query params.
         * To make sure they are correctly hydrated again, they need additional transformation instructions.
         * These are assigned by HttpGETRequestProperty decorator.
         * Here we throw an error if any model property has no transformation function assigned.
         */
        const meta = getAllClassTransformerMetadata(model);
        (meta.exposed || []).forEach(({
          propertyName
        }) => {
          if (!meta.transform?.has(propertyName)) {
            throw new Error('AppRequest ' + model + ' uses GET method but it seems that property ' + propertyName + ' was not decorated with HttpGETRequestProperty. ' + 'Please make sure all exposed props of the model are decorated.');
          }
        });
      }
    }
  };
}
export function isAppRequest(model) {
  const ModelCtor = model instanceof Model ? model.constructor : model;
  const meta = customMetadataStorage.findMetadataOfType(APP_REQUEST_KEY, ModelCtor);
  return meta.length > 0;
}
export function getAppRequestMeta(model) {
  const meta = customMetadataStorage.findMetadataOfType(APP_REQUEST_KEY, model);
  if (!meta.length) throw new Error('request model ' + model + ' has no AppRequest metadata!');
  return meta[meta.length - 1].options;
}
export function getModelsWithAppRequestMetadata() {
  return customMetadataStorage.findModelsByMetadataType(APP_REQUEST_KEY);
}
export function getModelsWithAppRequestMetadata$() {
  return customMetadataStorage.findModelsByMetadataType$(APP_REQUEST_KEY);
}