import { deepmerge } from 'deepmerge-ts';
import { BehaviorSubject, Subject, combineLatest, from, groupBy, map, merge, mergeMap, shareReplay, toArray } from 'rxjs';
import { FeatureStatusService } from './feature-status.service';
import { FeatureType } from './interfaces';
import { getClassName } from '../class-name';
// located in own file which is not exported from index, so it is "internal"
const MANDATORY_FEATURES = ['common-page',
// contains components used by core. As core itself must not rely on libs / ui, it cannot contain these itself.
'common-abilities',
// contains ability / skillset management 
'common-media' // asset management is considered mandatory because all apps and many features rely on media assets.
];
/**
 * INFO: This is just the basic FeatureRegistry class. On client, inject FeatureService to get loaded/activated features.
 */
export class FeatureRegistry {
  constructor(config) {
    this.config = config;
    this.featureStatus = new FeatureStatusService();
    this.baseRegistry$ = new BehaviorSubject([]);
    this.registerPlugin$ = new Subject();
    this.pluginRegistry$ = new BehaviorSubject([]);
    // list of all installed FeaturePlugins, independent of currently active user/company and its settings.
    this.allOptInFeaturePluginInfos$ = this.baseRegistry$.pipe(map(bases => {
      const allOptInsWithCalculatedInfo = bases.reduce((list, base) => {
        const plugins = base.resolveFeatureInfoFromStatic().plugins || [];
        const optIns = plugins.filter(p => p.type === FeatureType.OptIn);
        if (optIns.length) {
          optIns.forEach(info => {
            list.push(this.getMergedFeatureInfo(base, info));
          });
        }
        return list;
      }, []);
      return allOptInsWithCalculatedInfo;
    }), shareReplay(1));
    if (!IS_PRODUCTION) {
      this.validateFeatures();
      this.validatePlugins();
    }
  }
  validateFeatures() {
    if (!IS_PRODUCTION) {
      setTimeout(() => {
        const allFeatures = this.getBaseFeatures();
        // some features are not optional and must always be included.
        // check and throw if any of those is missing.
        MANDATORY_FEATURES.forEach(featureId => {
          const match = allFeatures.find(feature => feature.resolveFeatureInfoFromStatic().context.featureId === featureId);
          if (!match) throw new Error('A required feature is missing. Feature "' + featureId + '" is mandatory and must be included in features.json!');
        });
      }, 500);
    }
  }
  validatePlugins() {
    if (!IS_PRODUCTION) {
      setTimeout(() => {
        const currentPlatform = this.config.getPlatform();
        const allPlugins = this.getBaseFeatures().reduce((list, base) => {
          list.push(...(base.resolveFeatureInfoFromStatic().plugins || []));
          return list;
        }, []);
        const allPluginsForCurrentPlatform = allPlugins.filter(plugin => {
          if (plugin.forPlatform === currentPlatform) return true;
          if (plugin.forPlatform === 'backend-admin' && currentPlatform === 'backend') return true;
          return false;
        });
        // validate unique plugin class names
        const allPluginClassNamesForCurrentPlatform = allPluginsForCurrentPlatform.map(plugin => plugin.pluginClassName);
        allPluginClassNamesForCurrentPlatform.sort();
        allPluginClassNamesForCurrentPlatform.forEach((val, i) => {
          if (allPluginClassNamesForCurrentPlatform[i + 1] === val) {
            throw new Error('FeatureRegistry detected duplicate pluginClassName "' + val + '". Plugin classes must be unique!');
          }
        });
        // validate that all expected plugins have registered themselves
        const registeredPlugins = this.pluginRegistry$.getValue();
        allPluginsForCurrentPlatform.forEach(info => {
          const match = registeredPlugins.find(plugin => {
            // we only allow metadata based names here so that we get errors in dev env already, where true constructor name is still intact.
            return getClassName(plugin, true) === info.pluginClassName;
          });
          if (!match) {
            throw new Error('FeaturePlugin "' + info.pluginClassName + '" should be available on this platform but has not registered. Please correct its setup (extends correct class? Ensure it is decorated with @ClassName.)');
          }
        });
      }, 3000);
    }
  }
  getBaseRegistry() {
    return this.baseRegistry$.asObservable();
  }
  getBaseFeatures() {
    return this.baseRegistry$.getValue();
  }
  getPluginRegistry() {
    return this.pluginRegistry$.asObservable();
  }
  /**
   * pluginRegistry$ will emit all plugins when any is added.
   * Opposed to that, this observable executes once for any existing or added-in-future plugin.
   */
  observeEachPlugin() {
    return merge(from(this.pluginRegistry$.getValue()).pipe(groupBy(plugin => plugin.__info.name), mergeMap(group => group.pipe(toArray(), map(groupArray => groupArray[0])))), this.registerPlugin$.pipe(
      // tap(plugin=>console.log('observe newly registered plugin',plugin))
    ));
  }
  // must be called from child class constructor!
  setup(bases) {
    const baseFeatureInstances = bases.map(base => {
      const baseInstance = new base(this.config);
      return baseInstance;
    });
    this.baseRegistry$.next(baseFeatureInstances);
    this.registerPlugin$.subscribe(featurePlugin => {
      this.setupPlugin(featurePlugin, bases);
      this.validatePlugin(featurePlugin);
      const registeredPlugins = this.pluginRegistry$.value;
      this.pluginRegistry$.next([...registeredPlugins, featurePlugin]);
    });
    this.setupPluginStatusCalculation();
  }
  setupPlugin(featurePlugin, bases) {
    const [baseFeatureInstance, pluginInfo] = this.findBaseFeatureOfFeaturePlugin(featurePlugin, this.baseRegistry$.getValue());
    featurePlugin.pluginName = pluginInfo.pluginClassName;
    featurePlugin.__info = this.getMergedFeatureInfo(baseFeatureInstance, pluginInfo);
    featurePlugin.__def = this.getMergedFeatureDefinition(featurePlugin);
    featurePlugin.base = baseFeatureInstance;
  }
  setupPluginStatusCalculation() {
    // only frontend platform can handle plugins that are dynamic / optIn!
    // frontend featureRegistry overrides this method with more specific implementation.
    combineLatest({
      registeredFeatures: this.getPluginRegistry()
    }).subscribe(({
      registeredFeatures
    }) => {
      registeredFeatures.forEach(feature => {
        const status = this.featureStatus.setNewStatus(feature, [], null, []);
        this.updateFeatureStatus(feature, status);
      });
    });
  }
  // call from module initializer
  initialize() {
    // this.pluginRegistry$
  }
  getMergedFeatureInfo(base, pluginInfo) {
    const fullName = base.resolveFeatureInfoFromStatic().context.featureId + '.' + pluginInfo.pluginClassName;
    return deepmerge({}, pluginInfo, {
      name: fullName
    });
  }
  getMergedFeatureDefinition(feature) {
    const def = feature.getFeatureDefinition();
    return deepmerge({}, def);
  }
  findBaseFeatureOfFeaturePlugin(feature, baseFeatures) {
    let className = getClassName(feature);
    let match;
    // TODO: Workaround for #86bxn7xr1 - compiler may add a digit to the end which breaks identification
    const endsWithDigit = new RegExp('\\d$');
    if (endsWithDigit.test(className)) {
      className = className.substring(0, className.length - 1);
    }
    for (let i = 0; i < baseFeatures.length; i++) {
      const featureInfo = baseFeatures[i].resolveFeatureInfoFromStatic();
      if (!featureInfo.plugins) continue;
      match = featureInfo.plugins.find(pluginDef => {
        return pluginDef.pluginClassName === className;
      });
      if (match) return [baseFeatures[i], match];
    }
    throw new Error("Could not find a BaseFeature that specifies '" + className + "' as a plugin!");
  }
  // centralized status management in case of future observability needs
  updateFeatureStatus(feature, patch) {
    this.featureStatus.patch(feature, patch);
  }
  // called by FeaturePlugin ctor self-registration
  add(feature) {
    this.registerPlugin$.next(feature);
  }
  /** development only! */
  reset() {
    this.baseRegistry$.next([]);
    this.pluginRegistry$.next([]);
  }
}