import { ItemRestraints } from '@core/shared/restraints';
export class MediaAssetRestraints extends ItemRestraints {
  canViewItem(item) {
    return true;
  }
  canCreateItem(item) {
    return this.requirePermission('ADMINISTRATOR');
  }
  canToggleItem(item) {
    return false;
  }
  canUpdateItem(item, oldItem) {
    return this.requirePermission('ADMINISTRATOR');
  }
  canRemoveItem(item) {
    return this.requirePermission('ADMINISTRATOR');
  }
}